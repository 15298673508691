import {useState, useEffect} from 'react';
import { FormattedMessage } from "react-intl";
import {
  TableContainer,
  List,
  Li,
  HeaderItem,
  Item,
} from "../../../../../../GlobalStyles";
import { ItemsNoOrdersContainer } from "./styles";
import { getRecommended } from './../../../../../../api/RecommendedService';
import { dateToMomentFormat } from "../../../../../../utils/DateFormat";

export const ItemsNoOrders = ({customerId, lang = 'es'}) => {

  const [lastProducts, setListProducts] = useState([]);
  //const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    loadProductsUnsoldRecommended();
  }, [customerId]);

  const loadProductsUnsoldRecommended = async () => {
    try {
      const response = await getRecommended(customerId);
      if (response.data.data.products) {
        setListProducts(response.data.data.products);
      } else {
        setListProducts([]);
      }
      //setCurrentDate(response.data.data.date_now)
    } catch (error) {
      console.log("loadProductsUnsoldRecommended error", error);
    }
  };

  return (
    <ItemsNoOrdersContainer>
      <TableContainer>
        <List>
          <Li>
            <HeaderItem>
              <FormattedMessage id="app.customer_unsold_recommended_products" />
              {` ${dateToMomentFormat(new Date(), "MMMM", lang)}`}
            </HeaderItem>
          </Li>
          {
            lastProducts.length > 0 ?
              lastProducts.map((item, key) => (
                <Li key={key}>
                  <Item>{item.name} <small>({item.reference})</small></Item>
                </Li>
              )) : <p> <FormattedMessage id="app.there_is_not_record" /> </p>
          }
        </List>
      </TableContainer>
    </ItemsNoOrdersContainer>
  );
};
