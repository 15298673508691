import { Fragment, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { FormattedMessage } from "react-intl";
import { getCustomersWithOutVisitsMetrics } from "../../../../api/CustomersWithoutVisits";
import { useSeller } from "../../../../contexts/SellerContext";
import {
  TitleInBox,
  TableContainer,
  List,
  Li,
  HeaderItem,
  Item,
} from "../../../../GlobalStyles";
import {
  BackNextButtom,
  ButtonsNextBackWrapper,
  CustomersNotVisitsContainer,
  NoMoreResults,
  PageNumber,
} from "./styles";
import { dateToMomentFormat } from "../../../../utils/DateFormat";
import { IconContext } from "react-icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

export const CustomersNotVisits = () => {
  const { sellerData } = useSeller();
  const [page, setPage] = useState(1);
  const [limitDate, setLimitDate] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [customersData, setCustomersData] = useState([]);

  useEffect(() => {
    loadCustomersWithoutVisitsMetrics();
  }, [page]);

  const loadCustomersWithoutVisitsMetrics = async () => {
    try {
      const response = await getCustomersWithOutVisitsMetrics(
        sellerData.user_id,
        page
      );
      const { customers, limit_date } = response.data.data;
      setCustomersData(customers);
      setLimitDate(limit_date);
      setHasMore(customers.length > 0);
    } catch (error) {
      console.log("loadCustomersWithoutVisitsMetrics error", error);
    }
  };

  const backPageHandler = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const nextPageHandler = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <Fragment>
      <CustomersNotVisitsContainer>
        <TitleInBox>
          {<FormattedMessage id="app.customers_without_visits_last_month" />} ({<FormattedMessage id="app.date_previous" />} {limitDate})
        </TitleInBox>
        <TableContainer>
          <List>
            <Li>
              <HeaderItem flex="7">
                <FormattedMessage id="app.customer_name" />
              </HeaderItem>
              <HeaderItem alignText="right" flex="3">
                <FormattedMessage id="app.customer_last_visit" />
              </HeaderItem>
            </Li>
            {customersData.map((item, key) => (
              <Li key={key}>
                <Item data-tip={item.customer_name} flex="7">
                  {item.customer_name.length > 30
                    ? `${item.customer_name.substring(0, 30)} ...`
                    : item.customer_name}
                </Item>
                <Item alignText="right" flex="3">
                  {item.last_visit ? (
                    dateToMomentFormat(item.last_visit)
                  ) : (
                    <FormattedMessage id="app.there_is_not_record" />
                  )}
                </Item>
              </Li>
            ))}
          </List>
        </TableContainer>
        {!hasMore && (
          <NoMoreResults>
            <FormattedMessage id="app.there_is_not_records" />
          </NoMoreResults>
        )}

        <ButtonsNextBackWrapper>
          <BackNextButtom enable={page !== 1} onClick={backPageHandler}>
            <IconContext.Provider value={{ color: "#FFF", size: "15px" }}>
              <AiOutlineArrowLeft />
            </IconContext.Provider>
          </BackNextButtom>
          <PageNumber>
            {page}
          </PageNumber>
          <BackNextButtom enable={hasMore} onClick={nextPageHandler}>
            <IconContext.Provider value={{ color: "#FFF", size: "15px" }}>
              <AiOutlineArrowRight />
            </IconContext.Provider>
          </BackNextButtom>
        </ButtonsNextBackWrapper>
      </CustomersNotVisitsContainer>
      {customersData.length > 0 && <ReactTooltip />}
    </Fragment>
  );
};
