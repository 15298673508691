import styled from "styled-components";

export const GoalsContainer = styled.section`
    margin: 30px 0 10px;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    width: 100%;
`;

export const SalesGoalsContainer = styled.section`
    flex: 1;
    display: flex;
`;

export const CollectionGoalsContainer = styled.section`
    flex: 1;
    display: flex;
`;

export const TitleGoals = styled.h3`
    margin: 10px 0;
    padding: 0px;
    font-size: 1.05em;
    font-weight: 700;
`;