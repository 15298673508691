import { requestHttp } from "../utils/HttpRequest";

export const getCustomersWithOutVisitsMetrics = (idUser, page = 1) => {
  try {
    const response = requestHttp({
      endpoint: "customers_without_visits",
      data: {
        user_id: idUser,
        page
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
