import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconContext } from "react-icons";
import { AiOutlineCalendar } from "react-icons/ai";
import { DatePicketWrapper, IconDatePicker } from "./styles";

export const DatePickerFilter = ({ dateChoose, onChange, lang = "es" }) => {
  const _onChange = (value) => {
    onChange(value);
  };

  return (
    <DatePicketWrapper>
      <DatePicker
        selected={dateChoose}
        onChange={(date) => _onChange(date)}
        locale={lang}
      />
      <IconDatePicker>
        <IconContext.Provider value={{ color: "#444", size: "20px" }}>
          <AiOutlineCalendar />
        </IconContext.Provider>
      </IconDatePicker>
    </DatePicketWrapper>
  );
};
