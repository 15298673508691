import styled from "styled-components";

export const LeadsVsCustomersContainer = styled.section`
  border-radius: 10px;
  padding: 10px 20px 0px;
  margin: 5px 0;
  box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
  background-color: #fff;
  flex: 1;
`;

export const DataGraphWrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const DataContainer = styled.section`
  flex: 60;
`;

export const GraphContainer = styled.section`
  flex: 40;
`;
