import styled from "styled-components";

export const HomeWrapper = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 50px;
`;

export const SalesManName = styled.h2`
    font-weight: 800;
    margin: 7px 0;
`;

export const CustomersWrapper = styled.section`
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    flex: 1;
    flex-wrap: wrap;
`;

export const IndicatorsWrapper  = styled.section`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    margin-top: 20px;
`;

export const CustomersVsLeadsWrapper = styled.section`
    display: flex;
    flex: 35;
`;

export const ProductsCustomerWrapper = styled.section`
    margin-top: 20px;
`;

export const FilterDateContainer = styled.section`
    margin-top: 20px;
    display: flex;
`;

export const DatePicketWrapper = styled.section`
    background-color: #fff;
    box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
    border-radius: 5px;
    display: flex;
    align-items: center;
    input {
        border: none;
        outline: none;
        padding: 5px 10px;
        font-size: 1em;
        color: #222;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }
`;

export const IconDatePicker = styled.div`
    margin: 0 10px;
`;