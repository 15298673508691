import { requestHttp } from "../utils/HttpRequest";

export const getLastOrderCustomer = (customerId) => {
  try {
    const response = requestHttp({
      endpoint: "last_order_customer",
      data: {
        customer_id: customerId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
