import { requestHttp } from "../utils/HttpRequest";

export const getFindCustomers = (idUser, find, limit) => {
  try {
    const response = requestHttp({
      endpoint: "find_customers_available",
      data: {
        user_id: idUser,
        find,
        limit,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
