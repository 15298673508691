import styled from "styled-components";

export const ItemsOrderRecurrentContainer = styled.div`
    flex: 20;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
    background-color: #fff;
    margin: 0 10px;
    @media (max-width: 768px) {
        margin: 15px 0;
    }
`;