import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Header } from "../components/Header";
import { useSeller } from "../contexts/SellerContext";
import { Container, ContentContainer } from "../GlobalStyles";
import { Home } from "./Home";
import { Splash } from "./Splash";
import { NotFound } from "./NotFound";

const AuthApp = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <ContentContainer>
        <Container>{children}</Container>
      </ContentContainer>
    </Fragment>
  );
};

const WithoutAuth = ({ children }) => {
  return children;
};

export const NavigationApp = () => {
  const { sellerData } = useSeller();

  return (
    <Router>
      <Switch>
        {!sellerData && (
          <WithoutAuth>
            <Route exact path="/">
              <Splash title="Splash" />
            </Route>
            <Route path="/404">
              <NotFound title="404" />
            </Route>
            <Route path="/seller">
              <Redirect to="/" />
            </Route>
          </WithoutAuth>
        )}

        {sellerData?.user_id && (
          <AuthApp>
            <Route path="/seller">
              <Home title="Dashboard" />
            </Route>
          </AuthApp>
        )}
      </Switch>
    </Router>
  );
};
