import styled from "styled-components";

export const DatePicketWrapper = styled.section`
  background-color: #fff;
  box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  input {
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 1em;
    color: #222;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
  }
`;

export const IconDatePicker = styled.div`
  margin: 0 10px;
`;
