import { Fragment } from "react";
import { GlobalStyle } from "./GlobalStyles";
import { NavigationApp } from "./pages/NavigationApp";

const App = () => (
  <Fragment>
    <GlobalStyle />
    <NavigationApp />
  </Fragment>
)

export default App;
