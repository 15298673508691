import { FormattedMessage } from "react-intl";
import { Label, ValueLabel } from "../../GlobalStyles";
import { MiniCardContainer, BoxIcon } from "./styles";
import { IconContext } from "react-icons";

export const MiniCard = ({ icon: Icon, value, label, attaLabel, colorIcon = "#ccc", backgroundIcon }) => {
  return (
    <MiniCardContainer>
      <BoxIcon backgroundIcon={backgroundIcon}>
        <IconContext.Provider value={{ color: colorIcon, size: "30px" }}>
          <Icon />
        </IconContext.Provider>
      </BoxIcon>
      <ValueLabel>
        {value}
      </ValueLabel>
      <Label>
        <FormattedMessage id={label} />
        <strong>{` ${attaLabel}`}</strong>
      </Label>
    </MiniCardContainer>
  );
};
