export const LS_KEYS = {
  USER_ID: "user_id",
  IDR: "idr",
  LANG: "lang",
  NAME: "name"
};

export const getLSValue = (key) => {
  return localStorage.getItem(key);
};

export const setLSValue = (key, value) => {
  localStorage.setItem(key, value);
};
