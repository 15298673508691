import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #FaFaFa;
  }
  a {
    text-decoration: none;
  }
`;

export const ContentContainer = styled.article`
  margin-top: 65px;
`;

export const Container = styled.section`
  margin: 0 20px;
  display: flex;
  padding: 5px 0;
`;

export const BoxFlexContainer = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
`;


export const Title2 = styled.h2`
    margin: 10px 0;
    padding: 0px;
    font-size: 1.1em;
    font-weight: 700;
`;

export const TitleInBox = styled.h4`
  font-size: 1.05em;
  color: #222;
  font-weight: 700;
  padding: 0;
  margin: 0 0 4px;
`;

export const Label = styled.h6`
    color: #aeaeae;
    font-size: 0.8em;
    margin: 0;
    padding: 0;
    font-weight: 400;
`;

export const ValueLabel = styled.h3`
    color: #222;
    font-size: 1.3em;
    margin: 9px 0;
    padding: 0;
    font-weight: 700;
`;

export const TableContainer = styled.div`
    padding: 0;
    margin: 0;
`;

export const List = styled.section`
    display: flex;
    flex: 1;
    margin: 0px;
    padding: 0;
    flex-direction: column;
`;
export const Li = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    border-bottom: 2px solid #f2f2f2;
    padding: 5px 0;
`;
export const HeaderItem = styled.h6`
    font-weight: bold;
    font-size: 0.95em;
    padding: 0;
    margin: 0;
    flex: ${props => props.flex ? props.flex : 1 };
    color: #222;
    text-align: ${props => props.alignText ? props.alignText : 'left' };
`;
export const Item = styled.p`
    font-weight: 400;
    padding: 0;
    margin: 0;
    flex: ${props => props.flex ? props.flex : 1 };
    font-size: 0.85em;
    color: #222;
    text-align: ${props => props.alignText ? props.alignText : 'left' };
`;