import { useState, useEffect } from "react";
import { MiniCard } from "../../../../components/MiniCard";
import { MiniCardsWrapper } from "./styles";
import {
  AiOutlineFileDone,
  AiOutlineSync,
  AiOutlineUserSwitch,
  AiOutlineContacts,
} from "react-icons/ai";
import { BoxFlexContainer } from "../../../../GlobalStyles";
import { getOrderMetrics } from "../../../../api/OrderMetricsService";
import { getVisitsMetrics } from "../../../../api/VisitsMetricsService";
import { useSeller } from "../../../../contexts/SellerContext";
import { useLangApp } from "../../../../contexts/LangContext";
import { dateToMomentFormat } from "../../../../utils/DateFormat";

export const Indicators = ({ date }) => {
  const { sellerData } = useSeller();
  const { currentLang } = useLangApp();
  const [ordersData, setOrdersData] = useState(null);
  const [visitsData, setVisitsData] = useState(null);

  useEffect(() => {
    if (date) {
      loadOrderDataMetrics();
      loadVisitsDataMetrics();
    }
  }, [date]);

  const loadOrderDataMetrics = async () => {
    try {
      const response = await getOrderMetrics(sellerData.user_id, date);
      setOrdersData(response.data.data);
    } catch (error) {
      console.log("loadSalesDataGoals error", error);
    }
  };

  const loadVisitsDataMetrics = async () => {
    try {
      const response = await getVisitsMetrics(sellerData.user_id, date);
      setVisitsData(response.data.data);
    } catch (error) {
      console.log("loadSalesDataGoals error", error);
    }
  };

  return (
    <MiniCardsWrapper>
      <BoxFlexContainer>
        <MiniCard
          backgroundIcon={"rgba(10, 205, 129, 0.2)"}
          colorIcon={"#0ACD81"}
          icon={AiOutlineFileDone}
          label="app.total_orders"
          attaLabel={`${dateToMomentFormat(date, "MMMM", currentLang.name)}`}
          value={ordersData?.total_orders}
        />
        <MiniCard
          backgroundIcon={"rgba(10, 205, 129, 0.2)"}
          colorIcon={"#0ACD81"}
          icon={AiOutlineUserSwitch}
          label="app.total_visits"
          attaLabel={`${dateToMomentFormat(date, "MMMM", currentLang.name)}`}
          value={visitsData?.total_visits}
        />
      </BoxFlexContainer>
      <BoxFlexContainer>
        <MiniCard
          backgroundIcon={"rgba(94, 148, 248, 0.2)"}
          colorIcon={"#5E94F8"}
          icon={AiOutlineSync}
          label="app.total_orders_sync"
          attaLabel={`${dateToMomentFormat(date, "MMMM", currentLang.name)}`}
          value={ordersData?.total_orders_sync}
        />
        <MiniCard
          backgroundIcon={"rgba(253, 107, 106, 0.2)"}
          colorIcon={"#FD6B6A"}
          icon={AiOutlineContacts}
          label="app.total_visits_newness"
          attaLabel={`${dateToMomentFormat(date, "MMMM", currentLang.name)}`}
          value={visitsData?.total_visits_newness}
        />
      </BoxFlexContainer>
    </MiniCardsWrapper>
  );
};
