import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { getCustomersLeadsMetrics } from "../../../../api/CustomerLeadsMetricsService";
import { useLangApp } from "../../../../contexts/LangContext";
import { useSeller } from "../../../../contexts/SellerContext";
import { Label, TitleInBox, ValueLabel } from "../../../../GlobalStyles";
import { dateToMomentFormat } from "../../../../utils/DateFormat";
import { GraphLeadsVsCustomers } from "./components/GraphLeadsVsCustomers";
import {
  LeadsVsCustomersContainer,
  DataGraphWrapper,
  DataContainer,
  GraphContainer,
} from "./styles";

export const LeadVsCustomers = ({ date }) => {
  const { sellerData } = useSeller();
  const { currentLang } = useLangApp();
  const [customerLeadsData, setCustomerLeadsData] = useState(null);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (date) {
      loadCustomersLeadsMetrics();
    }
  }, [date]);

  const loadCustomersLeadsMetrics = async () => {
    try {
      const response = await getCustomersLeadsMetrics(sellerData.idr,sellerData.user_id, date);
      const { data } = response.data;
      setCustomerLeadsData(data);
      const leasAndCustomers = data.total_customers + data.total_leads;
      const percentValue = Math.floor(
        (data.total_customers * 100) /
          (leasAndCustomers === 0 ? 1 : leasAndCustomers)
      );
      setPercentage(percentValue);
    } catch (error) {
      console.log("loadSalesDataGoals error", error);
    }
  };

  return (
    <LeadsVsCustomersContainer>
      <TitleInBox>
        <FormattedMessage id="app.customers_vs_leads" />
      </TitleInBox>
      <DataGraphWrapper>
        <DataContainer>
          <ValueLabel>
            {customerLeadsData?.total_leads}
          </ValueLabel>
          <Label>
            <FormattedMessage id="app.leads_created" />
          </Label>
          <ValueLabel>{customerLeadsData?.total_customers}</ValueLabel>
          <Label>
            <FormattedMessage id="app.customers_created" />
          </Label>
        </DataContainer>
        <GraphContainer>
          <GraphLeadsVsCustomers percentage={percentage} />
        </GraphContainer>
      </DataGraphWrapper>
    </LeadsVsCustomersContainer>
  );
};
