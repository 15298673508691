import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { LangAppProvider } from './contexts/LangContext';
import { SellerProvider } from './contexts/SellerContext';

ReactDOM.render(
  <React.StrictMode>
    <LangAppProvider>
      <SellerProvider>
        <App />
      </SellerProvider>
    </LangAppProvider>
  </React.StrictMode>,

  document.getElementById('root')
);
