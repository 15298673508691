import { requestHttp } from "../utils/HttpRequest";

export const getCustomersLeadsMetrics = (idr,user_id, date) => {
  try {
    const response = requestHttp({
      endpoint: "customers_metrics",
      data: {
        idr: idr,
        user_id: user_id,
        chosen_date: date,

      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
