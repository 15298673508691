import { requestHttp } from "../utils/HttpRequest";

export const getCollectionMetrics = (idUser, date) => {
  try {
    const response = requestHttp({
      endpoint: "collection_metrics",
      data: {
        user_id: idUser,
        chosen_date: date,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
