import { createContext, useContext, useState } from "react";

const initialState = null;

const SellerContext = createContext(initialState);

export const SellerProvider = ({ children }) => {
  const [sellerData, setSellerData] = useState(initialState);
  
  const updateSellerData = (data) => {
    setSellerData(data);
  };

  return (
    <SellerContext.Provider value={{ sellerData, updateSellerData }}>
      {children}
    </SellerContext.Provider>
  );
};

export const useSeller = () => useContext(SellerContext);
