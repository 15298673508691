import { Fragment, useEffect, useState } from "react";
import { CustomersNotVisits } from "./Components/CustomersNotVisits";
import { Goals } from "./Components/Goals";
import { Indicators } from "./Components/Indicators";
import { LeadVsCustomers } from "./Components/LeadsVsCustomers";
import { ProductsCustomers } from "./Components/ProductsCustomers";
import { TopCustomers } from "./Components/TopCustomers";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import {
  HomeWrapper,
  CustomersWrapper,
  IndicatorsWrapper,
  CustomersVsLeadsWrapper,
  ProductsCustomerWrapper,
  FilterDateContainer,
  SalesManName,
} from "./styles";
import { useLangApp } from "../../contexts/LangContext";
import { dateToMomentFormat } from "../../utils/DateFormat";
import { DatePickerFilter } from "../../components/DatePickerFilter";
import { useSeller } from "../../contexts/SellerContext";

registerLocale("es", es);

export const Home = ({ title = "Dashboard" }) => {
  const [startDateChoose, setStartDateChoose] = useState(new Date());
  const [dateChoose, setDateChoose] = useState(null);

  const { currentLang } = useLangApp();
  const { sellerData } = useSeller();

  useEffect(() => {
    onChangeStartDateChoose(new Date());
  }, []);

  const onChangeStartDateChoose = (value) => {
    const mdate = dateToMomentFormat(value);
    setStartDateChoose(value);
    setDateChoose(mdate);
  };

  return (
    <Fragment>
      <HomeWrapper>
        <SalesManName>
          { sellerData.name }
        </SalesManName>
        <FilterDateContainer>
          <DatePickerFilter
            lang={currentLang.name}
            dateChoose={startDateChoose}
            onChange={onChangeStartDateChoose}
          />
        </FilterDateContainer>
        <Goals date={dateChoose} />
        <CustomersWrapper>
          <TopCustomers date={dateChoose} />
          <CustomersNotVisits />
        </CustomersWrapper>
        <IndicatorsWrapper>
          <Indicators date={dateChoose} />
          <CustomersVsLeadsWrapper>
            <LeadVsCustomers date={dateChoose} />
          </CustomersVsLeadsWrapper>
        </IndicatorsWrapper>
        <ProductsCustomerWrapper>
          <ProductsCustomers date={dateChoose} />
        </ProductsCustomerWrapper>
      </HomeWrapper>
    </Fragment>
  );
};
