import { createContext, useContext, useState } from "react";
import { IntlProvider } from 'react-intl';
import { LS_KEYS, setLSValue } from "../utils/LocalStorageUser";

import English from "./../lang/en.json";
import Spanish from "./../lang/es.json";

const LANGS = [
  {
    intl: English,
    name: "en",
  },
  {
    intl: Spanish,
    name: "es",
  },
];

const initialState = LANGS[1];

const LangAppContext = createContext(initialState);

export const LangAppProvider = ({ children }) => {
  const [currentLang, setCurrentLang] = useState(initialState);
  
  const updateLangApp = (newLang) => {
    setLSValue(LS_KEYS.LANG, newLang);
    setCurrentLang(LANGS[newLang]);
  }

  return (
    <LangAppContext.Provider value={{ currentLang, updateLangApp }}>
      <IntlProvider locale={currentLang.name} messages={currentLang.intl}>
        {children}
      </IntlProvider>
    </LangAppContext.Provider>
  );
};

export const useLangApp = () => useContext(LangAppContext);