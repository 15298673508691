import { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import AsyncSelect from "react-select/async";
import { getFindCustomers } from "../../../../api/FindCustomers";
import { DatePickerFilter } from "../../../../components/DatePickerFilter";
import { useLangApp } from "../../../../contexts/LangContext";
import { useSeller } from "../../../../contexts/SellerContext";
import { Title2 } from "../../../../GlobalStyles";
import { ItemsNoOrders } from "./components/ItemsNoOrders";
import { LastOrder } from "./components/LastOrder";
import { ItemsOrderRecurrent } from "./components/ItemsOrderRecurrent";
import {
  FilterInput,
  ProductsCustomerDataWrapper,
  FilterByCustomerWrapper,
} from "./styles";
import { dateToMomentFormat } from "../../../../utils/DateFormat";

export const ProductsCustomers = () => {
  const { sellerData } = useSeller();
  const { currentLang } = useLangApp();

  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [dateCustomerChoose, setDateCustomerChoose] = useState(new Date());

  useEffect(() => {
    loadFindCustomers("");
  }, []);

  const loadFindCustomers = async (find) => {
    try {
      const response = await getFindCustomers(sellerData.user_id, find, 50);
      const { customers } = response.data.data;
      const customerList = [];
      for (let i = 0; i < customers.length; i++) {
        const item = customers[i];
        customerList.push({
          value: item.customer_id,
          label: item.customer_name,
        });
      }
      return customerList;
    } catch (error) {
      console.log("loadFindCustomers error", error);
      return [];
    }
  };

  const loadOptions = (inputValue) => {
    return new Promise(async (resolve) => {
      const customers = await loadFindCustomers(inputValue);
      resolve(customers);
    });
  };

  const onChangeHandle = (item) => {
    setIsLoading(false);
    setCustomerId(item.value);
    setCustomerName(item.label);
  };

  const onChangeCustomerPickerHandler = (value) => {
    setDateCustomerChoose(value);
  };

  return (
    <Fragment>
      <Title2>
        <FormattedMessage id="app.title_customer_detail" />
      </Title2>
      <FilterByCustomerWrapper>
        <FilterInput>
          <AsyncSelect
            placeholder={<FormattedMessage id="app.select_customer" />}
            cacheOptions
            isLoading={isLoading}
            loadOptions={loadOptions}
            defaultOptions
            onChange={onChangeHandle}
            isSearchable
          />
        </FilterInput>
        <DatePickerFilter
          lang={currentLang.name}
          dateChoose={dateCustomerChoose}
          onChange={onChangeCustomerPickerHandler}
        />
      </FilterByCustomerWrapper>
      {customerId && (
        <ProductsCustomerDataWrapper>
          <LastOrder
            customerName={customerName}
            customerId={customerId}
            lang={currentLang.name}
          />
          <ItemsOrderRecurrent
            date={dateToMomentFormat(dateCustomerChoose)}
            idr={sellerData.idr}
            customerId={customerId}
            lang={currentLang.name}
          />
          <ItemsNoOrders customerId={customerId} />
        </ProductsCustomerDataWrapper>
      )}
    </Fragment>
  );
};
