import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useLangApp } from "../../contexts/LangContext";
import { useSeller } from "../../contexts/SellerContext";
import { useQuery } from "../../hooks/useQuery";
import { requestHttp } from "../../utils/HttpRequest";
import { getLSValue, LS_KEYS, setLSValue } from "../../utils/LocalStorageUser";

const SplashWrapper = styled.div`
  background: rgb(7, 61, 114);
  background: linear-gradient(
    90deg,
    rgba(7, 61, 114, 1) 0%,
    rgba(7, 61, 114, 1) 35%,
    rgba(12, 78, 93, 1) 100%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Splash = () => {
  const query = useQuery();
  const { updateSellerData } = useSeller();
  const { updateLangApp } = useLangApp();

  const history = useHistory();

  useEffect(() => {
    if (query.get("code")) {
      const code = query.get("code");
      const lang = query.get("lang");
      requesSellerData(code, lang);
    } else {
      const code = getLSValue(LS_KEYS.USER_ID);
      if (code) {
        const name = getLSValue(LS_KEYS.NAME);
        const lang = getLSValue(LS_KEYS.LANG);
        const idr = getLSValue(LS_KEYS.IDR);
        const sellerData = {
            user_id: code,
            idr,
            name
        }
        updateLangApp(lang)
        updateSellerData(sellerData);
        history.push('/seller');
      } else {
        history.push('/404');
      }
    }
  }, [query]);

  const requesSellerData = async (code, lang) => {
    try {
      const response = await requestHttp({
        endpoint: "seller",
        data: { user_id: code },
      });
      const { data: seller } = response.data;
      if (!seller) {
          throw('null user');
      }
      setLSValue(LS_KEYS.USER_ID, code);
      setLSValue(LS_KEYS.LANG, lang === 'es' ? 1 : 0);
      setLSValue(LS_KEYS.NAME, `${seller.first_name} ${seller.last_name}`);
      setLSValue(LS_KEYS.IDR, seller.idr);
      history.push('/');
    } catch (error) {
        history.push('/404');
    }
  };

  return (
    <React.Fragment>
      <SplashWrapper>
        <img
          alt="logo"
          src="https://web.movilventas.com/img/layout/movilventas/logo-blanco_200x97.png"
        />
      </SplashWrapper>
    </React.Fragment>
  );
};
