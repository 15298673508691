import { FormattedMessage } from "react-intl";
import { Container } from "../../GlobalStyles";
import { FaHome } from 'react-icons/fa';
import {
  HeaderContainer,
  LanguageButton,
  LanguageContainer,
  AppTitleContainer,
  LogoContainer,
  OptionsContainer,
  ExitAppContainer,
} from "./styles";
import { useLangApp } from "../../contexts/LangContext";

export const Header = () => {

  const {currentLang, updateLangApp} = useLangApp();

  const changeLang = (lang) => {
    updateLangApp(lang);
  }

  return (
    <HeaderContainer>
      <Container>
        <LogoContainer>
          <img
            alt="logo"
            width="100px"
            src="https://web.movilventas.com/img/layout/movilventas/logo-blanco_200x97.png"
          />
        </LogoContainer>
        <AppTitleContainer>
            <p><span>|</span> <FormattedMessage id="app.title" defaultMessage="Dashboard" /></p>
        </AppTitleContainer>
        <OptionsContainer>
          <LanguageContainer>
            <LanguageButton onClick={e => changeLang(0)} isActive={currentLang.name === 'en'}>
              <FormattedMessage id="app.english" defaultMessage="English" />
            </LanguageButton>
            <LanguageButton onClick={e => changeLang(1)} isActive={currentLang.name === 'es'}>
              <FormattedMessage id="app.spanish" defaultMessage="Spanish" />
            </LanguageButton>
          </LanguageContainer>
          {/*
          <ExitAppContainer>
              <a href="#"><FaHome /></a>
          </ExitAppContainer>
          */}
        </OptionsContainer>
      </Container>
    </HeaderContainer>
  );
};
