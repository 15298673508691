import { FormattedMessage } from "react-intl";
import CurrencyFormat from 'react-currency-format';
import {
  BoxComplianceContainer,
  ProgressWrapper,
  ProgressBarWrapper,
  PercentLabel,
} from "./styles";
import { Label, ValueLabel } from "../../GlobalStyles";
import ProgressBar from "@ramonak/react-progress-bar";
import { Fragment, useEffect, useState } from "react";

export const BoxCompliance = ({
  goal,
  compliance,
  gross,
  labeltop = "default",
  labelmiddle = "default",
  labelbottom = "default",
  additionalLabel = "default",
}) => {

  const [percentCompliance, setPercentCompliance] = useState(0);

  useEffect(() => {
    if (goal && compliance) {
      const value = Math.floor(compliance * 100 / goal);
      setPercentCompliance(value);
    }
  }, [compliance, goal]);

  return (
    <BoxComplianceContainer>
      {goal && (
        <Fragment>
          <Label>
            <FormattedMessage id={labeltop} />
          </Label>
          <ValueLabel>
            <CurrencyFormat value={goal} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </ValueLabel>
        </Fragment>
      )}

      <Label>
        <FormattedMessage id={labelmiddle} />
      </Label>
      <ValueLabel> 
        <CurrencyFormat value={compliance ? compliance : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      </ValueLabel>
      <br />
      {gross && (
        <Fragment>
          <Label>
            <FormattedMessage id={additionalLabel} />
          </Label>
          <ValueLabel> 
            <CurrencyFormat value={gross ? gross : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </ValueLabel>
        </Fragment>
          
      )}
      <br />
      {goal && (
        <Fragment>
          <Label>
            <FormattedMessage id={labelbottom} />
          </Label>
          <ProgressWrapper>
            <ProgressBarWrapper>
              <ProgressBar
                height={"10px"}
                isLabelVisible={false}
                bgColor={"#5E94F8"}
                completed={percentCompliance}
              />
            </ProgressBarWrapper>
            <PercentLabel>{percentCompliance}%</PercentLabel>
          </ProgressWrapper>
        </Fragment>
      )}
    </BoxComplianceContainer>
  );
};
