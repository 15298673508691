import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getLastOrderCustomer } from "../../../../../../api/LastOrderCustomer";
import {
  TableContainer,
  List,
  Li,
  HeaderItem,
  Item,
} from "../../../../../../GlobalStyles";
import { dateToMomentFormat } from "../../../../../../utils/DateFormat";
import { LastOrderContainer } from "./styles";

export const LastOrder = ({ customerName, customerId, lang = 'es' }) => {
  const [lastOrders, setLastOrders] = useState([]);

  useEffect(() => {
    loadLastOrder();
  }, [customerId]);

  const loadLastOrder = async () => {
    try {
      const response = await getLastOrderCustomer(customerId);
      setLastOrders(response.data.data.last_orders);
    } catch (error) {
      console.log("loadSalesDataGoals error", error);
    }
  };

  return (
    <LastOrderContainer>
      <TableContainer>
        <List>
          <Li>
            {/*<HeaderItem>
              <FormattedMessage id="app.customer_name" />
            </HeaderItem>*/}
            <HeaderItem>
              <FormattedMessage id="app.customer_last_order" />
            </HeaderItem>
          </Li>

          {lastOrders.length === 0 && (
            <Li>
              <Item>
                <FormattedMessage id="app.there_is_not_record" />
              </Item>
            </Li>
          )}

          {lastOrders.map((item, key) => (
            <Li key={key}>
              <Item>{dateToMomentFormat(item.order_date, 'DD MMMM YYYY', lang)}</Item>
            </Li>
          ))}
        </List>
      </TableContainer>
    </LastOrderContainer>
  );
};
