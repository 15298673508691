import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getCollectionMetrics } from "../../../../api/CollectionMetricsService";
import { getOrderSalesMetrics } from "../../../../api/OrderSalesMetricsService";
import { BoxCompliance } from "../../../../components/BoxCompliance";
import { useLangApp } from "../../../../contexts/LangContext";
import { useSeller } from "../../../../contexts/SellerContext";
import { BoxFlexContainer } from "../../../../GlobalStyles";
import { dateToMomentFormat } from "../../../../utils/DateFormat";
import {
  CollectionGoalsContainer,
  GoalsContainer,
  SalesGoalsContainer,
  TitleGoals,
} from "./styles";

export const Goals = ({ date }) => {
  const { sellerData } = useSeller();
  const {currentLang} = useLangApp();
  const [orderSalesData, setOrderSalesData] = useState(null);
  const [collectionData, setCollectionData] = useState(null);

  useEffect(() => {
    if (date) {
      loadSalesDataGoals();
      loadCollectionDataGoals();
    }
  }, [date]);

  const loadSalesDataGoals = async () => {
    try {
      const response = await getOrderSalesMetrics(sellerData.user_id, date);
      setOrderSalesData(response.data.data);
    } catch (error) {
      console.log("loadSalesDataGoals error", error);
    }
  };

  const loadCollectionDataGoals = async () => {
    try {
      const response = await getCollectionMetrics(sellerData.user_id, date);
      setCollectionData(response.data.data);
    } catch (error) {
      console.log("loadCollectionDataGoals error", error);
    }
  };

  return (
    <GoalsContainer>
      <BoxFlexContainer>
        <TitleGoals>
          <FormattedMessage
            id="app.sales_goals"
            defaultMessage="Metas de ventas"
          />
          {` ${dateToMomentFormat(date, "MMMM", currentLang.name)}`}
        </TitleGoals>
        <SalesGoalsContainer>
          <BoxCompliance
            goal={orderSalesData?.goal_month}
            compliance={orderSalesData?.total_sales_month}
            gross={orderSalesData?.total_gross_moth}
            labeltop="app.sales_goals_month"
            labelmiddle="app.sales_month"
            labelbottom="app.monthly_sales_fulfillment"
            additionalLabel="app.sales_gross_month"
          />
          <BoxCompliance
            goal={orderSalesData?.goal_day}
            compliance={orderSalesData?.total_sales_day}
            gross={orderSalesData?.total_gross_day}
            labeltop="app.sales_goals_day"
            labelmiddle="app.sales_day"
            labelbottom="app.daily_sales_fulfillment"
            additionalLabel="app.sales_gross_day"
          />
        </SalesGoalsContainer>
      </BoxFlexContainer>
      <BoxFlexContainer>
        <TitleGoals>
          <FormattedMessage
            id="app.collections_goals"
            defaultMessage="Metas de cobros"
          />
          {` ${dateToMomentFormat(date, "MMMM", currentLang.name)}`}
        </TitleGoals>
        <CollectionGoalsContainer>
          <BoxCompliance
            compliance={collectionData?.total_collection_month}
            labeltop="app.collection_goals_month"
            labelmiddle="app.collection_month"
            labelbottom="app.monthly_collection_fulfillment"
          />
          <BoxCompliance
            compliance={collectionData?.total_collection_day}
            labeltop="app.collection_goals_day"
            labelmiddle="app.collection_day"
            labelbottom="app.daily_collection_fulfillment"
          />
        </CollectionGoalsContainer>
      </BoxFlexContainer>
    </GoalsContainer>
  );
};
