import styled from "styled-components";

export const MiniCardContainer = styled.div`
    display: flex;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0;
    margin: 5px;
    box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
    background-color: #fff;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const BoxIcon = styled.div`
    padding: 8px 12px;
    border-radius: 10px;
    background-color: ${props => props.backgroundIcon || "#ccc"};
    margin-bottom: 5px;
`;