import styled from "styled-components";

export const HeaderContainer = styled.header`
    background: rgb(7, 61, 114);
    background: linear-gradient(90deg, rgba(7, 61, 114, 1) 0%, rgba(7, 61, 114, 1) 35%, rgba(12, 78, 93, 1) 100%);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    max-height: 60px;
    min-height: 60px;
    padding: 0px;
    z-index: 1;
`;

export const LogoContainer = styled.div`
    //flex: 1;
`;

export const AppTitleContainer = styled.div`
    flex: 1;
    justify-content: flex-start;
    margin-left: 30px;
    p {
        color: #e3e3e3;
        font-weight: 300;
        @media (max-width: 768px) {
            display: none;
        }
    }
    span {
        color: #fff;
        padding: 0 20px;
    }
`;


export const OptionsContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

export const LanguageContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 30px;
`;

export const LanguageButton = styled.button`
    border: none;
    margin: 0 5px;
    color: #fff;
    font-size: 1em;
    background: ${props => props.isActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent' };
    cursor: pointer;
    padding: 6px 12px;
    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
`;

export const ExitAppContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        svg {
            color: #fff;
            font-size: 1.3em;
        }
    }
    a:hover {
        background: rgba(255, 255, 255, 0.2);
    }
`;