import { Fragment } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const GraphLeadsVsCustomers = ({ percentage = 66 }) => (
  <Fragment>
    <CircularProgressbar
      value={percentage}
      text={`${percentage}%`}
      circleRatio={0.75}
      styles={buildStyles({
        textColor: "#222",
        rotation: 1 / 2 + 1 / 8,
        strokeLinecap: "butt",
        trailColor: "#eee",
        pathColor: "#5E94F8"
      })}
    />
  </Fragment>
);
