import styled from "styled-components";

export const CustomersNotVisitsContainer = styled.section`
    flex: 42;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 5px 0;
    box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
    background-color: #fff;
`;

export const NoMoreResults = styled.div`
    margin: 15px 0;
    text-align: center;
    color: #666;
`;

export const PageNumber = styled.p`
    color: #444;
    font-size: 0.89em;
`;

export const ButtonsNextBackWrapper = styled.section`
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    align-items: center;
`;

export const BackNextButtom = styled.div`
    cursor: pointer;
    border-radius: 50%;
    color: #fff;
    padding: 7px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.enable ? '#5E94F8' : '#ccc' };
`;