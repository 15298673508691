import { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import { FormattedMessage } from "react-intl";
import { getOrderSalesMetrics } from "../../../../api/OrderSalesMetricsService";
import { getToSellingCustomersMetrics } from "../../../../api/TopSellingCustomers";
import { useLangApp } from "../../../../contexts/LangContext";
import { useSeller } from "../../../../contexts/SellerContext";
import { Label, TitleInBox, ValueLabel } from "../../../../GlobalStyles";
import { dateToMomentFormat } from "../../../../utils/DateFormat";
import { GraphTopCustomers } from "./components/GraphTopCustomers";
import {
  TopCustomersContainer,
  DataGraphWrapper,
  DataContainer,
  GraphContainer,
} from "./styles";

export const TopCustomers = ({ date }) => {
  const { sellerData } = useSeller();
  const { currentLang } = useLangApp();
  const [orderSalesData, setOrderSalesData] = useState(null);
  const [topSellingData, setTopSellingData] = useState([]);

  useEffect(() => {
    if (date) {
      loadOrderSalesDataMetrics();
      loadTopSellingCustomersDataMetrics();
    }
  }, [date]);

  const loadOrderSalesDataMetrics = async () => {
    try {
      const response = await getOrderSalesMetrics(sellerData.user_id, date);
      setOrderSalesData(response.data.data);
    } catch (error) {
      console.log("loadOrderSalesDataMetrics error", error);
    }
  };

  const loadTopSellingCustomersDataMetrics = async () => {
    try {
      const response = await getToSellingCustomersMetrics(
        sellerData.user_id,
        date
      );
      setTopSellingData(response.data.data.customers);
    } catch (error) {
      console.log("loadTopSellingCustomersDataMetrics error", error);
    }
  };

  return (
    <TopCustomersContainer>
      <TitleInBox>
        <FormattedMessage id="app.title_top_sales_customers" />
      </TitleInBox>
      <DataGraphWrapper>
        <DataContainer>
          <br />
          <ValueLabel>
            <CurrencyFormat
              value={
                orderSalesData?.total_sales_month !== null
                  ? orderSalesData?.total_sales_month
                  : 0
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </ValueLabel>
          <Label>
            <FormattedMessage id="app.total_sales_on" />
            <strong>{` ${dateToMomentFormat(date, "MMMM", currentLang.name)}`}</strong>
          </Label>
          <br />
          <ValueLabel>{orderSalesData?.total_units}</ValueLabel>
          <Label>
            <FormattedMessage id="app.total_sales_items_on" />
            <strong>{` ${dateToMomentFormat(date, "MMMM", currentLang.name)}`}</strong>
          </Label>
        </DataContainer>
        <GraphContainer>
          <GraphTopCustomers lang={currentLang.name} topSelling={topSellingData} />
        </GraphContainer>
      </DataGraphWrapper>
    </TopCustomersContainer>
  );
};
