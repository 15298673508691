import styled from "styled-components";

export const BoxComplianceContainer = styled.div`
    //border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0;
    margin-right: 10px;
    box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
    background-color: #fff;
    flex: 1;
`;

export const HorizontalDiv = styled.div`
    margin: 0 15px;
`;

export const ProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ProgressBarWrapper = styled.div`
    width: 100%;
`;

export const PercentLabel = styled.h6`
    color: #999;
    font-size: 1em;
    padding: 0px;
    margin: 0px;
    margin-left: 8px;
`;