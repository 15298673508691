import styled from "styled-components";

export const TopCustomersContainer = styled.section`
  border-radius: 10px;
  padding: 10px 20px;
  margin: 5px 0;
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
  box-shadow: 1px 1px 1px 1px rgba(178, 177, 177, 0.12);
  background-color: #fff;
  flex: 65;
`;

export const DataGraphWrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  flex-wrap: wrap;
`;

export const DataContainer = styled.section`
  flex: 25;
`;

export const GraphContainer = styled.section`
  flex: 75;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
