import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { getProductsRecurrent } from "../../../../../../api/ProductsRecurrent";
import {
  TableContainer,
  List,
  Li,
  HeaderItem,
  Item,
} from "../../../../../../GlobalStyles";
import { dateToMomentFormat } from "../../../../../../utils/DateFormat";
import { ItemsOrderRecurrentContainer } from "./styles";

export const ItemsOrderRecurrent = ({ customerId, idr, date, lang = 'en' }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadProductsRecurrent();
  }, [customerId, date]);

  const loadProductsRecurrent = async () => {
    try {
      const response = await getProductsRecurrent(customerId, idr, date);
      setProducts(response.data.data.products);
    } catch (error) {
      console.log("loadProductsRecurrent error", error);
    }
  };

  return (
    <ItemsOrderRecurrentContainer>
      <TableContainer>
        <List>
          <Li>
            <HeaderItem flex={3}>
              <FormattedMessage id="app.customer_recurring_products_reference" />
            </HeaderItem>
            <HeaderItem flex={7}>
              <FormattedMessage id="app.customer_recurring_products" />
              {` ${dateToMomentFormat(date, "MMMM", lang)}`}
            </HeaderItem>
            <HeaderItem alignText="right" flex={3}>
              <FormattedMessage id="app.customer_recurring_products_quantity" />
            </HeaderItem>
          </Li>
          {
            products.length > 0 ? 
              products.map((item, key) => (
                <Li key={key}>
                  <Item flex={3}>{item.product_reference}</Item>  
                  <Item flex={7}>{item.product_name}</Item>
                  <Item alignText="right" flex={3}>{item.total_quantity}</Item>
                </Li>
              )) : <p><FormattedMessage id="app.there_is_not_record" /></p>
          }
          
        </List>
      </TableContainer>
    </ItemsOrderRecurrentContainer>
  );
};
