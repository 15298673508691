import { requestHttp } from "../utils/HttpRequest";

export const getProductsRecurrent = (customerId, idr, date) => {
  try {
    const response = requestHttp({
      endpoint: "top_selling_products_customer",
      data: {
        customer_id: customerId,
        idr: idr,
        chosen_date: date,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
