import styled from "styled-components";

export const FilterByCustomerWrapper = styled.section`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProductsCustomerDataWrapper = styled.section`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  margin-top: 20px;
`;

export const FilterInput = styled.section`
  min-width: 200px;
  margin: 0 10px;
  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;
